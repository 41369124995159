import type { SinglePlan } from "../interfaces/SinglePlan";

export const AvailablePlans: Omit<SinglePlan, "id" | "monthlyPrice" | "yearlyPrice">[] = [
	{
		title: "Free",
		iconName: ["save-later_bulk_01", "save-later_bulk_02"],
		numAuditRoom: 2,
		numUser: 2,
		storageAvailable: "50 MB",
		dwgDocs: 0,
		hasSyncFolder: true,
	},
	{
		title: "Pro",
		iconName: ["heart_bulk_01", "heart_bulk_02"],
		numAuditRoom: 5,
		numUser: 2,
		storageAvailable: "500 GB",
		dwgDocs: 200,
	},
	{
		title: "Business",
		iconName: ["star_bulk_01", "star_bulk_02"],
		numAuditRoom: 10,
		numUser: 10,
		storageAvailable: "1 TB",
		dwgDocs: 1000,
	},
	{
		title: "Company",
		iconName: ["profile_bulk_02_01", "profile_bulk_01_01"],
		numAuditRoom: 20,
		numUser: 20,
		storageAvailable: "2 TB",
		dwgDocs: 2000,
	},
	{
		title: "Enterprise",
		iconName: ["send_bulk_01", "send_bulk_02"],
		numAuditRoom: 20,
		numUser: 30,
		storageAvailable: "4 TB",
		dwgDocs: 3000,
	},
];
